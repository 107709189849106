import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer, EuiButton } from '@elastic/eui';
import { OutboundLink } from 'gatsby-plugin-gtag';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // SD Card Storage",
  "path": "/Web_User_Interface/720p_Series/Alarm/SD_Card/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Please check the corresponding box and click submit to either format or unmount your SD card. It is recommended to always unmount your SD card before removing it from your camera! Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors. The SD card will show up again once it was successfully mounted by the system.",
  "image": "./WebUI_720p_SearchThumb_Alarm_SD_Card.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_SD_Card.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Alarm Menu // SD Card Storage' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Please check the corresponding box and click submit to either format or unmount your SD card. It is recommended to always unmount your SD card before removing it from your camera! Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors. The SD card will show up again once it was successfully mounted by the system.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_SD_Card.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_SD_Card.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/SD_Karte/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/SD_Card/' crumbLabel="SD Card" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "sd-card-storage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sd-card-storage",
        "aria-label": "sd card storage permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SD Card Storage`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Please check the corresponding box and click submit to either format or unmount your SD card. It is recommended to always unmount your SD card before removing it from your camera! Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors. The SD card will show up again once it was successfully mounted by the system.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/6bbf7/WebUI-Alarm_SD.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAACsElEQVQ4y6VUSVMaURicv5VK/kas+GviwYMegwdlXAgieDCAscpdNhEIqKVCRAMoy7DKMqgHSy+d6g9nDEYTS6nqeo83b3q6v01ZXPyOjwMDePf+Az4NDmJmxgabzQan0wnbjA3T09Pyf25uDnb7V1lVVYXVqsJut8PpcmF2dhaq1YpJdRLK8vIyxsfHMTI6KmR+v18QCAT69sFgEIFAb+/z+QR8Fg6HkUgk7p8HoMy7XPJVj8eLra0tnJycoFgs4vz8/L8ol8tCODw8jP39fRwcHEBxOBwYGxsTi2tra8hms0gmk0in09D1Llqt1pNoNpvodDrQNA0Wi0WISapQ3cTEBLxeL9bX15HP51GpVGQtFUtoNBq4uLh4EvV6XchpNxaL9QipbGpqCm63GysrKyiVSijkC2L99PQUtVoduq6LmscwlNJZNBrtETqYIVWVbLrdHrGQy+aQPj4W0kwmY8as2Wzh6uoK3W4Xl5eXJujMJKTVbwsLiMfjCIVCPYWFghAxntlsRvaMKdezszMTuVxOErOxsfFAWCmXJauddkfkV6tVUXZ0dIRUKmViZ2dH4sQ9k0YcHh4K8ebm5gNhtVIRi7TCuDAh19fXfZYInvHO4/O/LJc1DZlfGckaFZKQSWDA2+22CaNc/jwjKKKPsFGrQe/o4I8qGBMG3bj8HAxCfryPkKUyMjKKoc9DWFpaMgv21YQsbIvli4AZp0J2yKsJQ8EQYrEfkkWfzw9NK73NcipplMZP7O7uSmG/iTASjWB1ddU8JCEvMpbPDQaj5Qje7Ws9WmVhEtyzfO7u7nBzc/Mi3N7eymw0CRPxuKjjIQdlJBIRcs7Gl4Btx8Sydff29qCwnUjGacsYkoyThxn3eDz/BO/wLkmpju8r3IS3txG9n2ecukavvhR8h2RU+BtHQ6uesAHxZQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/e4706/WebUI-Alarm_SD.avif 230w", "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/d1af7/WebUI-Alarm_SD.avif 460w", "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/04ac8/WebUI-Alarm_SD.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/a0b58/WebUI-Alarm_SD.webp 230w", "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/bc10c/WebUI-Alarm_SD.webp 460w", "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/d8378/WebUI-Alarm_SD.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/81c8e/WebUI-Alarm_SD.png 230w", "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/08a84/WebUI-Alarm_SD.png 460w", "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/6bbf7/WebUI-Alarm_SD.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3830ccc16ac8ab2a64cfe7bfbaa70e10/6bbf7/WebUI-Alarm_SD.png",
              "alt": "Web User Interface - 720p Series - Alarm SD Card",
              "title": "Web User Interface - 720p Series - Alarm SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "the-sd-card-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-sd-card-menu",
        "aria-label": "the sd card menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The SD Card Menu`}</h3>
    <p>{`Please check the corresponding box and click submit to either format or unmount your SD card. It is recommended to always unmount your SD card before removing it from your camera! Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors. The SD card will show up again once it was successfully mounted by the system.`}</p>
    <p>{`A click on the link above the submit button will lead you to the SD card directory where all your snapshots and videos will be stored. Use your camera admin login to access the directory. But there are also more convenient ways to access your recordings via `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/SD_Card_Access/"
      }}>{`Third-Party Software`}</a>{` or our mobile phone app InstarVision for `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
      }}>{`iPhone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPad/Recording/"
      }}>{`iPad`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/Recording/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/Recording/"
      }}>{`Windows Phone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/Recording/"
      }}>{`Windows 8/10`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <OutboundLink href="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">SD Card Calculator</EuiButton></OutboundLink>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Der Ursprung einer Aufnahme (Video oder Einzelbild) auf der SD Karte oder dem FTP Server, kann man anhand des Vorgestellten Buchstabens im Dateinamen ableiten:`}</p>
    <ul>
      <li parentName="ul">{`Alarmaufnahmen: `}<strong parentName="li">{`A`}</strong></li>
      <li parentName="ul">{`Geplante Aufnahme / Fotoserie: `}<strong parentName="li">{`P`}</strong></li>
      <li parentName="ul">{`Manuelle Aufnahme: `}<strong parentName="li">{`N`}</strong></li>
      <li parentName="ul">{`FTP Test: `}<strong parentName="li">{`T`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Beispiel`}</strong>{`: Die Datei `}<strong parentName="p">{`P17073016245100.jpg`}</strong>{` stammt aus einer Fotoserie und wurde am 2017 (Jahr). 07 (Monat). 30 (Tag) um 16:24:51 aufgenommen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      